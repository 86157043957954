import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_ANIMATIONS, MAPPED_SYMBOLS_STOP_ANIMATIONS, SlotId } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setIsAnticipation, setNextResult } from '../../gql/cache';
import { destroySpine, isScatter } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  DEFAULT_SPRITE_COLOR,
  NOT_WIN_LINE_SPRITE_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';

import { BASE_SLOT_SPINE_ANIMATE_PRIORITY, STOP_SPECIAL_SYMBOL_PRIORITY, WILD_SPINE_ANIMATE_PRIORITY } from './config';
import { SpineAnimateSlot } from './spineAnimateSlot';

class SlotsAnimationContainer extends ViewContainer {
  private stopSymbolAnimations: Animation[] = [];

  private slotSymbols: SpineAnimateSlot[] = [];

  public animation?: AnimationChain | undefined;

  private simbolSpineContainer: Spine[] = [];

  private simbolSpriteContainer: PIXI.Sprite[] = [];

  private isSpinResult = false;

  constructor() {
    super();
    this.sortableChildren = true;

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT * REELS_AMOUNT; i++) {
      this.simbolSpineContainer[i] = new Spine(
        PIXI.Loader.shared.resources[MAPPED_SYMBOLS_ANIMATIONS[SlotId.WL].src!]!.spineData!,
      );
      this.simbolSpriteContainer[i] = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[SlotId.WL]));
    }

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.START_GET_AMOUNT_WIN, this.skipStopSymbolAnimations.bind(this));
    eventManager.on(EventTypes.SETUP_REEL_POSITIONS, this.onSetupBetResult.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.SPIN_END, this.slotSpritesColorReset.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showStopSlotsDisplay.bind(this));
  }

  private clearSymbols(): void {
    this.slotSymbols.forEach((symbol) => {
      symbol.skip();
    });
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private initSymbols(spinResult: Icon[]): void {
    this.clearSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = new SpineAnimateSlot(
          spinResult[i * REELS_AMOUNT + j]!.id,
          j * SLOTS_PER_REEL_AMOUNT + i,
          this.simbolSpineContainer![i * REELS_AMOUNT + j]!,
          this.simbolSpriteContainer![i * REELS_AMOUNT + j]!,
        );
        symbol.x = REEL_WIDTH * j + REEL_WIDTH / 2;
        symbol.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(symbol);
        this.slotSymbols.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private showStopSlotsDisplay(spinResult: Icon[]) {
    this.animation?.skip();
    this.animation = undefined;
    this.initSymbols(spinResult!);
    this.slotSymbols.forEach((symbol) => (symbol.visible = true));
  }

  private onStartSpin() {
    this.slotSymbols.forEach((symbol) => {
      this.removeChild(symbol);
    });
    this.isSpinResult = false;
    this.skipStopSymbolAnimations();
  }

  private onAnticipationStart(): void {
    this.slotSymbols.forEach((slot) => {
      if (isScatter(slot.slotId)) {
        slot.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      } else {
        slot.setTint(ANTICIPATION_SLOTS_TINT);
      }
    });
  }

  private resetSlotsTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.setTint(DEFAULT_SPRITE_COLOR);
    });
  }

  private onSetupBetResult(): void {
    if (this.isSpinResult) return;

    this.initSymbols(setNextResult()!.bet.result.spinResult);
    this.isSpinResult = true;
  }

  private onReelStopped(reelId: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const symbol = this.slotSymbols[i * REELS_AMOUNT + reelId!]!;
      symbol.visible = true;
      symbol.startStopAnimation();
    }
    this.startOnSymbolsStopAnimations(reelId);
  }

  private startOnSymbolsStopAnimations(reelId: number): void {
    if (reelId === 0) this.stopSymbolAnimations = [];
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slotId = setNextResult()?.bet.result.spinResult[i * REELS_AMOUNT + reelId]!.id;
      if (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId] && isScatter(slotId)) {
        const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId!];
        if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src]!.spineData);
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnStart(() => {
          animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT * i + 2.3;
          animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId + 0.3;
          this.addChild(animation.getSpine());
          animation.getSpine().zIndex = STOP_SPECIAL_SYMBOL_PRIORITY;
          animation.setAnimation(animationData.animation!, false);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = false;
        });
        dummy.addOnComplete(() => {
          destroySpine(animation);
          if (reelId === 1) {
            setIsAnticipation(false);
          }
          this.removeChild(animation.spine);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = true;
        });
        dummy.addOnSkip(() => {
          destroySpine(animation);
          setIsAnticipation(false);
          this.removeChild(animation.spine);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = true;
        });
        this.stopSymbolAnimations.push(dummy);
        dummy.start();
      }
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private onStartWinAnimation(nextResult: ISettledBet, _isTurboSpin: boolean) {
    this.showWin(nextResult);
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.slotSymbols.forEach((symbol) => symbol.skip());
    this.slotSpritesColorReset();
  }

  private showWin(nextResult: ISettledBet): void {
    const { paylines } = nextResult;

    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {
      // eventManager.emit(EventTypes.SHOW_TINT, false);
      // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
    });

    const set = new Set<number>();
    paylines.forEach((payLine) => {
      payLine.winPositions.forEach((position) => {
        set.add(position);
      });
    });

    {
      const allSlotsHighlight = this.highlightSlots(Array.from(set));
      allSlotsHighlight.addOnStart(() => {
        // eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
        this.setWinSlotTint(Array.from(set));
      });
      allSlotsHighlight.addOnComplete(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        // eventManager.emit(EventTypes.SHOW_TINT, false);
      });
      allSlotsHighlight.addOnSkip(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        // eventManager.emit(EventTypes.SHOW_TINT, false);
      });
      this.animation.appendAnimation(allSlotsHighlight);
    }

    {
      const eachSlotsHighlight = this.createHighlightChainAnimation(paylines, true);
      this.animation.appendAnimation(eachSlotsHighlight);
    }
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((position) => {
      animationGroup.addAnimation(this.slotSymbols[position]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions);
      chain.addOnStart(() => {
        // eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        this.setWinSlotTint(payline.winPositions);
      });

      chain.addOnComplete(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
        this.slotSpritesColorChange();
      });

      // chain.addOnSkip(() => {
      //   eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
      // });

      animationChain.appendAnimation(chain);
    });
    return animationChain;
  }

  private slotSpritesColorChange(): void {
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.setTint(NOT_WIN_LINE_SPRITE_COLOR);
    }
  }

  private slotSpritesColorReset(): void {
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.setTint(DEFAULT_SPRITE_COLOR);
      this.slotSymbols[i]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
    }
  }

  private setWinSlotTint(slotPositions: number[]): void {
    this.slotSymbols.forEach((slot) => {
      slot.setTint(NOT_WIN_LINE_SPRITE_COLOR);
    });
    slotPositions.forEach((slot) => {
      this.slotSymbols[slot]!.setTint(DEFAULT_SPRITE_COLOR);
      if (isScatter(this.slotSymbols[slot]!.slotId)) {
        this.slotSymbols[slot]!.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      } else {
        this.slotSymbols[slot]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
      }
    });
  }
}

export default SlotsAnimationContainer;
